import React from 'react';
import {NavLink} from 'react-router-dom';

const Stock = ({locale}) => {
    return (
        <div className="modalStock-wrapper">
            <div className="modalStock">
                <h2 className="modalVacancy-title">{locale.modalStockTitle}</h2>
                <ul className="modalStock-list">
                    <li className="modalStock-listItem"><span>{locale.modalStockListItem1[0]}</span><span>{locale.modalStockListItem1[1]}</span></li>
                    <li className="modalStock-listItem"><span>{locale.modalStockListItem2[0]}</span><span>{locale.modalStockListItem2[1]}</span></li>
                    <li className="modalStock-listItem">
                        <span>{locale.modalStockListItem3[0]}</span><span>{locale.modalStockListItem3[1]}</span></li>
                    <li className="modalStock-listItem"><span>{locale.modalStockListItem4[0]}</span><span>{locale.modalStockListItem4[1]}</span></li>
                    <li className="modalStock-listItem"><span>{locale.modalStockListItem5[0]}</span><span>{locale.modalStockListItem5[1]}</span></li>
                </ul>
                <NavLink to="/">
                <svg className="modalStock-closeBtn" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <line x1="1.31573" y1="22.2708" x2="23.2219" y2="1.71541" stroke="black" strokeWidth="2"/>
                    <line x1="22.5576" y1="22.5904" x2="2.00227" y2="0.684258" stroke="black" strokeWidth="2"/>
                </svg>
                </NavLink>
            </div>
        </div>
    )
}

export default Stock;