import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import AOS from 'aos'

import logo from '../img/nmz_logo.svg';
import subMenuImg from "../img/subMenuImg.svg";
import phone from "../img/phone.svg";
import circlTitle from "../img/circlTitle.svg";
import approach from "../img/approach.svg";
import technology from "../img/technology.svg";
import quality from "../img/quality.svg";
import service from "../img/service.svg";
import serviceImg1 from "../img/serviceImg1.svg";
import serviceImg2 from "../img/serviceImg2.svg";
import serviceImg3 from "../img/serviceImg3.svg";
import serviceImg4 from "../img/serviceImg4.svg";
import metinvest from "../img/metinvest.svg";
import dtek from "../img/dtek.svg";
import nmz_logo from "../img/nmz_logo.svg";
import adress from "../img/adress.svg";
import phone_footer from "../img/phone_footer.svg";
import email from "../img/email.svg";
import adress_home from "../img/adress_home.svg";

import './Main.css';

class Main extends Component {

    state = {
        showLangMenu: false,
        lastChoose: '#sectionProducts-positionItem1',
        lastChooseItem: '#Item1',
        lastId: 'Item1'
    }

    toggle = (name) => {
        this.setState(prevState => ({
            [name]: !prevState[name]
        }))
    }

    closeLangMenu = (event) => {
        if ((event.dataset && event.dataset.name !== 'lang') || !event.dataset) {
            this.setState({
                showLangMenu: false
            })
        }
    }

    chooseProduct = (event) => {
        const getId = event.target.id;
        const sectionProductsPosition = document.querySelector('#sectionProducts-position' + getId);
        const sectionProductsListItem = document.querySelector('#' + getId);
        sectionProductsPosition.classList.add('sectionProducts-positionIndex');
        sectionProductsListItem.classList.add('sectionProducts-list_itemBackGroundColor');
        if (this.state.lastId !== getId) {
            const sectionProductsPositionLast = document.querySelector(this.state.lastChoose);
            const sectionProductsListItem = document.querySelector(this.state.lastChooseItem);
            sectionProductsPositionLast.classList.remove('sectionProducts-positionIndex');
            sectionProductsListItem.classList.remove('sectionProducts-list_itemBackGroundColor');
        }
        this.setState({
            lastChoose: '#sectionProducts-position' + getId,
            lastChooseItem: '#' + getId,
            lastId: getId
        })
    }

    openSubmenu = () => {
        document.querySelector('.main-navMobileClose').classList.add('main-navMobile')
    }
    closeSubmenu = () => {
        document.querySelector('.main-navMobileClose').classList.remove('main-navMobile')
    }

    render() {
        let language = this.props.locale;
        AOS.init()

        return (
            <div className="main" onClick={(event) => this.closeLangMenu(event)}>
                <header className="wrapper-header" data-aos="zoom-in" data-aos-duration="300" id="main">
                    <div className="headerBox">
                        <div className="wrapper-logo">
                            <img src={logo} alt="" className="header-logo"/>
                            <span className="header-logoName">{language.logoName}</span>
                        </div>
                        <div className="wrapper-subMenu" onClick={this.openSubmenu}>
                            <img src={subMenuImg} alt="" className="header-subMenu"/>
                        </div>
                        <nav className="header-nav" id="menu">
                            <ul className="header-menu">
                                <li className="header-menu_item">
                                    <a className="header-menu_itemLink" href="#about">{language.headerMenu[0]}</a>
                                </li>
                                <li className="header-menu_item"><a className="header-menu_itemLink"
                                                                    href="#service">{language.headerMenu[1]}</a>
                                </li>
                                <li className="header-menu_item"><a className="header-menu_itemLink"
                                                                    href="#product">{language.headerMenu[2]}</a></li>
                                <li className="header-menu_item"><a className="header-menu_itemLink"
                                                                    href="#client">{language.headerMenu[3]}</a>
                                </li>
                                <li className="header-menu_item"><a className="header-menu_itemLink"
                                                                    href="#contacts">{language.headerMenu[4]}</a></li>
                                {/*<li className="header-menu_item"><a className="header-menu_itemLink" href="#sales">Раздел закупок</a></li>*/}
                            </ul>
                        </nav>
                        <div className="wrapper-headerLang">
                            <div className="header-langBox">
                                <div className="header-lang" data-name="lang" onClick={(event) => {
                                    this.toggle('showLangMenu');
                                    event.stopPropagation()
                                }}>{language.language}</div>
                                {this.state.showLangMenu && <ul className="header-lang_subMenuShow">
                                    <li className="header-lang_subMenuItem RU">
                                        <button className="header-lang_btn" onClick={this.props.changeLangRu}>
                                            RU
                                        </button>
                                    </li>
                                    <li className="header-lang_subMenuItem UK">
                                        <button className="header-lang_btn" onClick={this.props.changeLangUa}>
                                            UA
                                        </button>
                                    </li>
                                </ul>}
                            </div>
                        </div>
                        <nav className="main-navMobileClose">
                            <svg className="closeHeader_absoluteBtn" onClick={this.closeSubmenu} width="24" height="24" viewBox="0 0 24 24"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <line x1="1.31573" y1="22.2708" x2="23.2219" y2="1.71541" stroke="black"
                                      strokeWidth="2"/>
                                <line x1="22.5576" y1="22.5904" x2="2.00227" y2="0.684258" stroke="black"
                                      strokeWidth="2"/>
                            </svg>
                            <ul className="main-menuMobile" data-aos="fade-up">
                                <li className="main-menu_itemMobile onClickFunc" onClick={this.closeSubmenu}>{language.mainMenu[0]}
                                </li>
                                <li className="main-menu_itemMobile onClickFunc projectsLinkMobile"><NavLink to="/project">{language.mainMenu[1]}</NavLink>
                                </li>
                                <li className="main-menu_itemMobile onClickFunc stockLinkMobile"><NavLink to="/stock">{language.mainMenu[2]}</NavLink></li>
                                <li className="main-menu_itemMobile onClickFunc vacancyLinkMobile"><NavLink to="/vacancy">{language.mainMenu[3]}</NavLink>
                                </li>
                                <li className="main-menu_itemMobile onClickFunc saleLinkMobile"><NavLink to="/sale">{language.mainMenu[4]}</NavLink></li>
                            </ul>
                        </nav>
                    </div>
                </header>
                <main className="wrapper-main">
                    <nav className="main-nav">
                        <ul className="main-menu" data-aos="fade-up" data-aos-duration="500">
                            <li className="main-menu_item projectsLink">
                                <NavLink to="/project">{language.mainMenu[1]}</NavLink></li>
                            <li className="main-menu_item stockLink">
                                <NavLink to="/stock">{language.mainMenu[2]}</NavLink></li>
                            <li className="main-menu_item vacancyLink">
                                <NavLink to="/vacancy">{language.mainMenu[3]}</NavLink></li>
                            <li className="main-menu_item saleLink">
                                <NavLink to="/sale">{language.mainMenu[4]}</NavLink></li>
                        </ul>
                    </nav>
                    <section className="sectionTitle" >
                        <h1 className="sectionTitle-title" data-aos="fade-down" data-aos-duration="500">{language.sectionTitleTitle} <span
                            className="sectionTitle-titleTwoPart" data-aos="fade-down" data-aos-duration="300">{language.sectionTitleTitleTwoPart}</span>
                        </h1>
                        {/*<div id="linkToContacts">*/}
                        {/*<a className="linkSkype" href="#contacts">*/}
                        {/*<img src="../img/phone.svg" className="imgPhone" alt=""/>*/}
                        {/*</a>*/}
                        {/*</div>*/}
                        <a className="linkPhone" href="tel:+380506809508">
                            <img src={phone} className="imgPhone" alt=""/>
                        </a>
                        <div className="sectionTitle-content">
                            <div className="sectionTitle-slider">
                                <ul className="sectionTitle-slider_list">
                                    <li className="sectionTitle-slider_item" data-aos="fade-down" data-aos-duration="500">
                                        <img src={circlTitle} alt=""
                                             className="sectionTitle-slider_itemCirc"/>
                                        {/*<span className="sectionTitle-slider_itemCirc"></span>*/}
                                        <span
                                            className="sectionTitle-slider_itemText">{language.sectionTitleItemText1}</span>
                                    </li>
                                    <li className="sectionTitle-slider_item" data-aos="fade-down" data-aos-duration="300">
                                        {/*<span className="sectionTitle-slider_itemCirc"></span>*/}
                                        <img src={circlTitle} alt=""
                                             className="sectionTitle-slider_itemCirc"/>
                                        <span
                                            className="sectionTitle-slider_itemText">{language.sectionTitleItemText2}</span>
                                    </li>
                                    <li className="sectionTitle-slider_item" data-aos="fade-down" data-aos-duration="200">
                                        {/*<span className="sectionTitle-slider_itemCirc"></span>*/}
                                        <img src={circlTitle} alt=""
                                             className="sectionTitle-slider_itemCirc"/>
                                        <span
                                            className="sectionTitle-slider_itemText">{language.sectionTitleItemText3}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                    <section className="sectionAboutUs" id="about">
                        <article className="sectionAboutUs-article">
                            <h2 className="sectionAboutUs-title" data-aos="fade-right" data-aos-duration="300">{language.sectionAboutUsTitle}</h2>
                            <h3 className="sectionAboutUs-titleIn" data-aos="fade-right" data-aos-duration="500">{language.sectionAboutUsTitleIn}</h3>
                            <p className="sectionAboutUs-text" data-aos="fade-right" data-aos-duration="700">{language.sectionAboutUsText}</p>
                        </article>
                        <div className="sectionAboutUs-imgBox" data-aos="fade-left" data-aos-duration="500">
                            {/*<img src="../img/structure.svg" className="sectionAboutUs-img" alt=""/>*/}
                        </div>
                    </section>
                    <section className="sectionWorkPrinciple">
                        <h2 className="sectionWorkPrinciple-title" data-aos="fade-up" data-aos-duration="500">{language.sectionWorkPrincipleTitle}</h2>
                        <ul className="sectionWorkPrinciple-list">
                            <li className="sectionWorkPrinciple-list_item" data-aos="fade-right" data-aos-duration="300">
                                <img src={approach} className="sectionWorkPrinciple-list_itemImg" alt=""/>
                                <div className="sectionWorkPrinciple-list_itemBoxResume">
                                    <h3 className="sectionWorkPrinciple-list_itemBoxResumeTitle">{language.sectionWorkPrincipleItemTitle1}</h3>
                                    <img src={approach} className="sectionWorkPrinciple-list_itemImgIn"
                                         alt=""/>
                                    <p className="sectionWorkPrinciple-list_itemBoxResumeText">{language.sectionWorkPrincipleItemText1}</p>
                                </div>
                            </li>
                            <li className="sectionWorkPrinciple-list_item" data-aos="fade-left" data-aos-duration="700">
                                <img src={technology} className="sectionWorkPrinciple-list_itemImg"
                                     alt=""/>
                                <div className="sectionWorkPrinciple-list_itemBoxResume">
                                    <h3 className="sectionWorkPrinciple-list_itemBoxResumeTitle">{language.sectionWorkPrincipleItemTitle2}</h3>
                                    <img src={technology}
                                         className="sectionWorkPrinciple-list_itemImgIn" alt=""/>
                                    <p className="sectionWorkPrinciple-list_itemBoxResumeText">{language.sectionWorkPrincipleItemText2}</p>
                                </div>
                            </li>
                            <li className="sectionWorkPrinciple-list_item" data-aos="fade-right" data-aos-duration="300">
                                <img src={quality} className="sectionWorkPrinciple-list_itemImg" alt=""/>
                                <div className="sectionWorkPrinciple-list_itemBoxResume">
                                    <h3 className="sectionWorkPrinciple-list_itemBoxResumeTitle">{language.sectionWorkPrincipleItemTitle3}</h3>
                                    <img src={quality} className="sectionWorkPrinciple-list_itemImgIn"
                                         alt=""/>
                                    <p className="sectionWorkPrinciple-list_itemBoxResumeText">{language.sectionWorkPrincipleItemText3}</p>
                                </div>
                            </li>
                            <li className="sectionWorkPrinciple-list_item" data-aos="fade-left" data-aos-duration="700">
                                <img src={service} className="sectionWorkPrinciple-list_itemImg" alt=""/>
                                <div className="sectionWorkPrinciple-list_itemBoxResume">
                                    <h3 className="sectionWorkPrinciple-list_itemBoxResumeTitle">{language.sectionWorkPrincipleItemTitle4}</h3>
                                    <img src={service} className="sectionWorkPrinciple-list_itemImgIn"
                                         alt=""/>
                                    <p className="sectionWorkPrinciple-list_itemBoxResumeText">{language.sectionWorkPrincipleItemText4}</p>
                                </div>
                            </li>
                        </ul>
                    </section>
                    <section className="sectionOurService" id="service" data-aos="fade-up" data-aos-duration="200">
                        <h2 className="sectionOurService-title" data-aos="fade-right">{language.sectionOurServiceTitle}</h2>
                        <ul className="sectionOurService-imgBox">
                            <li className="sectionOurService-imgList_itemFirst" data-aos="fade-right" data-aos-duration="700">
                                {/*<img className="sectionOurService-imgList_itemImg" src="../img/blockImg.svg" alt=""/>*/}
                                <p className="sectionOurService-imgList_itemText">{language.sectionOurServiceImgListItemText1}</p>
                            </li>
                            <li className="sectionOurService-imgList_item" data-aos="fade-right" data-aos-duration="500">
                                {/*<img className="sectionOurService-imgList_itemImg" src="img/blockImg2.svg" alt=""/>*/}
                                <p className="sectionOurService-imgList_itemText">{language.sectionOurServiceImgListItemText2}</p>
                            </li>
                            <li className="sectionOurService-imgList_item" data-aos="fade-right" data-aos-duration="300">
                                {/*<img className="sectionOurService-imgList_itemImg" src="../img/blockImg2.svg" alt=""/>*/}
                                <p className="sectionOurService-imgList_itemText">{language.sectionOurServiceImgListItemText3}</p>
                            </li>
                        </ul>
                    </section>
                    <section className="sectionOurService-second">
                        <h2 className="sectionOurService-second_title" data-aos="fade-up" data-aos-duration="200">{language.sectionOurServiceSecond_title}</h2>
                        <p className="sectionOurService-second_text" data-aos="fade-up" data-aos-duration="300">{language.sectionOurServiceSecond_text}</p>
                        <ul className="sectionOurService-second_imgBox">
                            <li className="sectionOurService-secondImgList_item" data-aos="fade-up" data-aos-duration="300">
                                <img className="sectionOurService-secondImg" src={serviceImg1} alt=""/>
                                <p className="sectionOurService-secondImgList_itemText">{language.sectionOurServiceSecondImgList_itemText1}</p>
                            </li>
                            <li className="sectionOurService-secondImgList_item" data-aos="fade-up" data-aos-duration="500">
                                <img className="sectionOurService-secondImg" src={serviceImg2} alt=""/>
                                <p className="sectionOurService-secondImgList_itemText">{language.sectionOurServiceSecondImgList_itemText2}</p>
                            </li>
                            <li className="sectionOurService-secondImgList_item" data-aos="fade-up" data-aos-duration="700">
                                <img className="sectionOurService-secondImg" src={serviceImg3} alt=""/>
                                <p className="sectionOurService-secondImgList_itemText">{language.sectionOurServiceSecondImgList_itemText3}</p>
                            </li>
                            <li className="sectionOurService-secondImgList_item" data-aos="fade-up" data-aos-duration="900">
                                <img className="sectionOurService-secondImg" src={serviceImg4} alt=""/>
                                <p className="sectionOurService-secondImgList_itemText">{language.sectionOurServiceSecondImgList_itemText4}</p>
                            </li>
                        </ul>
                    </section>
                    <section className="sectionProducts" id="product">
                        <h2 className="sectionProducts-title" data-aos="fade-up" data-aos-duration="200">{language.sectionProductsTitle}</h2>
                        <ul className="sectionProducts-list">
                            <li className="sectionProducts-list_item sectionProducts-list_itemBackGroundColor"
                                id="Item1" onClick={(event) => this.chooseProduct(event)} data-aos="fade-right" data-aos-duration="300">
                                {language.sectionProductsList_item1}
                            </li>
                            <li className="sectionProducts-list_item"
                                id="Item2" onClick={(event) => this.chooseProduct(event)} data-aos="fade-left" data-aos-duration="300">{language.sectionProductsList_item2}</li>
                            <li className="sectionProducts-list_item"
                                id="Item3" onClick={(event) => this.chooseProduct(event)} data-aos="fade-left" data-aos-duration="500">{language.sectionProductsList_item3}</li>
                            <li className="sectionProducts-list_item"
                                id="Item4" onClick={(event) => this.chooseProduct(event)} data-aos="fade-left" data-aos-duration="700">{language.sectionProductsList_item4}</li>
                        </ul>
                        <ul className="sectionProducts-positionBox" data-aos="fade-right" data-aos-duration="300">
                            <li id="sectionProducts-positionItem1" className="sectionProducts-positionIndex">
                                <h3 className="sectionProducts-positionItem1_title">{language.sectionProductsPositionItem1_title}</h3>
                                <ul className="sectionProducts-positionItem1_list">
                                    <li className="sectionProducts-positionItem1_listItem">{language.sectionProductsPositionItem1_listItem1}
                                    </li>
                                    <li className="sectionProducts-positionItem1_listItem">{language.sectionProductsPositionItem1_listItem2}
                                    </li>
                                    <li className="sectionProducts-positionItem1_listItem">{language.sectionProductsPositionItem1_listItem3}
                                    </li>
                                    <li className="sectionProducts-positionItem1_listItem">{language.sectionProductsPositionItem1_listItem4}
                                    </li>
                                    <li className="sectionProducts-positionItem1_listItem">{language.sectionProductsPositionItem1_listItem5}
                                    </li>
                                    <li className="sectionProducts-positionItem1_listItem">{language.sectionProductsPositionItem1_listItem6}
                                    </li>
                                </ul>
                            </li>
                            <li id="sectionProducts-positionItem2">
                                <h3 className="sectionProducts-positionItem1_title">{language.sectionProductsPositionItem2_title}</h3>
                                <ul className="sectionProducts-positionItem1_list">
                                    <li className="sectionProducts-positionItem1_listItem">{language.sectionProductsPositionItem2_listItem1}
                                    </li>
                                    <li className="sectionProducts-positionItem1_listItem">{language.sectionProductsPositionItem2_listItem2}
                                    </li>
                                    <li className="sectionProducts-positionItem1_listItem">{language.sectionProductsPositionItem2_listItem3}</li>
                                    <li className="sectionProducts-positionItem1_listItem">{language.sectionProductsPositionItem2_listItem4}
                                    </li>
                                </ul>
                            </li>
                            <li id="sectionProducts-positionItem3">
                                <h3 className="sectionProducts-positionItem1_title">{language.sectionProductsPositionItem3_title}</h3>
                                <ul className="sectionProducts-positionItem1_list">
                                    <li className="sectionProducts-positionItem1_listItem">{language.sectionProductsPositionItem3_listItem1}
                                    </li>
                                    <li className="sectionProducts-positionItem1_listItem">{language.sectionProductsPositionItem3_listItem2}
                                    </li>
                                    <li className="sectionProducts-positionItem1_listItem">{language.sectionProductsPositionItem3_listItem3}
                                    </li>
                                    <li className="sectionProducts-positionItem1_listItem">{language.sectionProductsPositionItem3_listItem4}
                                    </li>
                                    <li className="sectionProducts-positionItem1_listItem">{language.sectionProductsPositionItem3_listItem5}
                                    </li>
                                </ul>
                            </li>
                            <li id="sectionProducts-positionItem4">
                                <h3 className="sectionProducts-positionItem1_title">{language.sectionProductsPositionItem4_title}</h3>
                                <ul className="sectionProducts-positionItem1_list">
                                    <li className="sectionProducts-positionItem1_listItem">{language.sectionProductsPositionItem4_listItem1}
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <h3 className="sectionProducts-titleConditions" data-aos="fade-up">{language.sectionProductsTitleConditions}</h3>
                        <ul className="sectionProducts-listConditions">
                            <li className="sectionProducts-listConditions_item" data-aos="fade-down" data-aos-duration="300">
                                <span
                                    className="sectionProducts-listConditions_itemNumber">{language.sectionProductsListConditions_itemNumber1}</span>
                                <span
                                    className="sectionProducts-listConditions_itemText">{language.sectionProductsListConditions_itemText1}</span>
                            </li>
                            <li className="sectionProducts-listConditions_item" data-aos="fade-down" data-aos-duration="500">
                                <span
                                    className="sectionProducts-listConditions_itemNumber">{language.sectionProductsListConditions_itemNumber2}</span>
                                <span
                                    className="sectionProducts-listConditions_itemText">{language.sectionProductsListConditions_itemText2}</span>
                            </li>
                            <li className="sectionProducts-listConditions_item" data-aos="fade-down" data-aos-duration="700">
                                <span
                                    className="sectionProducts-listConditions_itemNumber">{language.sectionProductsListConditions_itemNumber3}</span>
                                <span
                                    className="sectionProducts-listConditions_itemText">{language.sectionProductsListConditions_itemText3}</span>
                            </li>
                        </ul>
                    </section>
                    <section className="sectionUseEquipment">
                        <h3 className="sectionUseEquipment-title" data-aos="fade-up">{language.sectionUseEquipmentTitle1}</h3>
                        <ul className="sectionUseEquipment-list">
                            <li className="sectionUseEquipment-list_item" data-aos="zoom-in">{language.sectionUseEquipmentList1_item1}
                            </li>
                            <li className="sectionUseEquipment-list_item" data-aos="zoom-in">{language.sectionUseEquipmentList1_item2}
                            </li>
                            <li className="sectionUseEquipment-list_item" data-aos="zoom-in">{language.sectionUseEquipmentList1_item3}
                            </li>
                            <li className="sectionUseEquipment-list_item" data-aos="zoom-in">{language.sectionUseEquipmentList1_item4}</li>
                            <li className="sectionUseEquipment-list_item" data-aos="zoom-in">{language.sectionUseEquipmentList1_item5}</li>
                            <li className="sectionUseEquipment-list_item" data-aos="zoom-in">{language.sectionUseEquipmentList1_item6}
                            </li>
                        </ul>
                        <h3 className="sectionUseEquipment-title" data-aos="fade-up">{language.sectionUseEquipmentTitle2}</h3>
                        <ul className="sectionUseEquipment-list">
                            <li className="sectionUseEquipment-list_item" data-aos="zoom-in">{language.sectionUseEquipmentList2_item1}</li>
                            <li className="sectionUseEquipment-list_item" data-aos="zoom-in">{language.sectionUseEquipmentList2_item2}</li>
                            <li className="sectionUseEquipment-list_item" data-aos="zoom-in">{language.sectionUseEquipmentList2_item3}</li>
                            <li className="sectionUseEquipment-list_item" data-aos="zoom-in">{language.sectionUseEquipmentList2_item4}</li>
                        </ul>

                    </section>
                    <section className="sectionPartner" id="client">
                        <h2 className="sectionPartner-title" data-aos="fade-right">{language.sectionPartnerTitle}</h2>
                        <div className="sectionPartner-imgBox">
                            <div className="metinvestBox" data-aos="fade-left" data-aos-duration="500">
                                <a href="https://metinvestholding.com/" target="_blank"><img
                                    src={metinvest} className="metinvest" alt=""/></a>
                            </div>
                            <div className="dtekBox" data-aos="fade-left" data-aos-duration="700">
                                <a href="https://dtek.com/ua/" target="_blank"><img src={dtek}
                                                                                    className="dtek" alt=""/></a>
                            </div>
                        </div>
                    </section>
                </main>
                <footer className="footer" id="contacts" data-aos="zoom-in">
                    <div className="footer-menu">
                        <div className="wrapper-footerMenu">
                            <div className="wrapper-footerLogo">
                                <img src={nmz_logo} alt="" className="footer-logo"/>
                                <span className="footer-logoName">{language.footerLogoName}</span>
                            </div>
                            <ul className="footer-menu_list" id="menuSub">
                                <li className="footer-menu_listItem"><a className="footer-menu_listItem"
                                                                        href="#main">{language.footerMenu_list[0]}</a>
                                </li>
                                <li className="footer-menu_listItem"><a className="footer-menu_listItem"
                                                                        href="#about">{language.footerMenu_list[1]}</a>
                                </li>
                                <li className="footer-menu_listItem"><a className="footer-menu_listItem"
                                                                        href="#service">{language.footerMenu_list[2]}</a>
                                </li>
                                <li className="footer-menu_listItem"><a className="footer-menu_listItem"
                                                                        href="#product">{language.footerMenu_list[3]}</a>
                                </li>
                                <li className="footer-menu_listItem"><a className="footer-menu_listItem"
                                                                        href="#client">{language.footerMenu_list[4]}</a>
                                </li>
                                {/*<li className="footer-menu_listItem"><a className="footer-menu_listItem" href="#contacts">Контакты</a></li>*/}
                            </ul>
                            <ul className="footer-menu_listTwo">
                                <li className="footer-menu_listTwoItem projectsLinkBelow"><NavLink
                                    className="footer-menu_listTwoItem"
                                    to="/project">{language.footerMenu_listTwo[0]}</NavLink></li>
                                <li className="footer-menu_listTwoItem stockLinkBelow"><NavLink
                                    className="footer-menu_listTwoItem"
                                    to="/stock">{language.footerMenu_listTwo[1]}</NavLink></li>
                                <li className="footer-menu_listTwoItem vacancyLinkBelow"><NavLink
                                    className="footer-menu_listTwoItem"
                                    to="/vacancy">{language.footerMenu_listTwo[2]}</NavLink>
                                </li>
                                <li className="footer-menu_listTwoItem saleLinkBelow"><NavLink
                                    className="footer-menu_listTwoItem"
                                    to="/sale">{language.footerMenu_listTwo[3]}</NavLink></li>
                            </ul>
                            <div className="wrapperContacts">
                                {/*<h2 className="footer-title">Контакты</h2>*/}
                                <ul className="footer-list">
                                    <li className="footer-list_item"><img className="footer-list_itemImg"
                                                                          src={adress} alt=""/>
                                        <p className="footer-list_itemText">{language.footerList_itemText1}</p></li>
                                    <li className="footer-list_item"><img className="footer-list_itemImg"
                                                                          src={phone_footer}
                                                                          alt=""/>
                                        <p className="footer-list_itemText">{language.footerList_itemText2}</p></li>
                                    <li className="footer-list_item"><img className="footer-list_itemImg"
                                                                          src={email} alt=""/>
                                        <p className="footer-list_itemText">{language.footerList_itemText3}</p></li>
                                    <li className="footer-list_item"><img className="footer-list_itemImg"
                                                                          src={adress_home}
                                                                          alt=""/>
                                        <p className="footer-list_itemText">{language.footerList_itemText4}</p></li>
                                    {/*<li className="footer-list_item">*/}
                                    {/*<p className="footer-list_itemText legalAdress">ЕГРПОУ 40497911, р/с*/}
                                    {/*26008053719662, в*/}
                                    {/*ПАО "ПриватБанк" МФО 304795</p></li>*/}
                                </ul>
                            </div>
                        </div>
                        <p className="footer-ps">{language.footerPs}</p>
                    </div>
                </footer>
            </div>
        );
    }
}

export default Main;
