import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';

import axios from 'axios';

import backImage from '../img/back.png';

class Vacancy extends Component {

    state = {
        file: '',
        noFile: false
    }

    handleImageChange = (event, del = '') => {
        event.preventDefault();
        let fileInput = event.target.files
        let reader = new FileReader();
        if (fileInput && !!Object.keys(fileInput).length) {
            reader.onloadend = () => {
                this.setState({
                    file: fileInput[0],
                    noFile: false
                    // imagePreviewUrl: reader.result
                });
            }
            reader.readAsDataURL(fileInput[0])
        }
        if (del) {
            let file = document.getElementById('photoFile')
            file.value = ''
            this.setState({
                file: '',
                // imagePreviewUrl: ''
            });
        }
    }

    sendResume = () => {
        // let newVac = {
        //     file: this.state.file
        // }
        if (this.state.file !== '') {
            let formDataVac = new FormData();
            formDataVac.append('file', this.state.file, this.state.file.name);

            axios.post('http://95.47.113.171:7002/uploadVacancy', formDataVac)
                .then(result => {
                    if (result) {
                        console.log(result);
                    }
                })
                .catch(err => console.log(err))
        } else {
            this.setState({
                noFile: true
            })
        }
    }

    render() {
        let locale = this.props.locale
        return (

            <div className="modalVacancy-wrapper">
                <div className="modalVacancy">
                    <h2 className="modalVacancy-title">{locale.modalVacancyTitle}</h2>
                    <ul className="modalVacancy-list">
                        <li className="modalVacancy-listItem">{locale.modalVacancyListItem1}</li>
                        <li className="modalVacancy-listItem">{locale.modalVacancyListItem2}</li>
                    </ul>
                    <h3 className="modalVacancy-title">{locale.modalVacancySendTitle}</h3>
                    {/*<%= typeof msgVac != 'undefined' ? msgVac : '' %>*/}
                    <div className="modalVacancy-inputWrapper">
                        <div className='modalVacancy-form'>
                            <span className={this.state.noFile ? 'modalVacancy-file background-red' : 'modalVacancy-file'} onClick={() => this.fileInput.click()}>{locale.modalVacancyChooseFile}</span>
                            {this.state.file &&
                            <span
                                className='modalVacancy-fileName'>{[...this.state.file.name].length > 15 ? [...this.state.file.name].slice(0, 8).join('') + ' ...... ' + [...this.state.file.name].reverse().slice(0, 7).reverse().join('') : this.state.file.name}</span>}
                            {this.state.file &&
                            <svg className="modalVacancy-closeBtn" onClick={(event) => {
                                this.handleImageChange(event, 'deleteFile')
                            }} width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <line x1="1.31573" y1="22.2708" x2="23.2219" y2="1.71541" stroke="black"
                                      strokeWidth="2"/>
                                <line x1="22.5576" y1="22.5904" x2="2.00227" y2="0.684258" stroke="black"
                                      strokeWidth="2"/>
                            </svg>}
                        </div>
                        <span>
                                        <input className="invisible"
                                               id='photoFile'
                                               name="fileToUpload"
                                               type="file"
                                               onChange={(event) => this.handleImageChange(event)}
                                               ref={fileInput => this.fileInput = fileInput}/></span>
                        <button className="modalVacancy-listItem_resume" onClick={this.sendResume}>{locale.modalSaleSendButton}</button>
                    </div>
                    {/*<input className="serviceList_item_makePhoto" type="file" ref={fileInput => this.fileInput = fileInput} onChange={(event) => this.context.handleImageChange(event)}/>*/}
                    <NavLink to="/">
                        <svg className="modalVacancy-closeBtn" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <line x1="1.31573" y1="22.2708" x2="23.2219" y2="1.71541" stroke="black" strokeWidth="2"/>
                            <line x1="22.5576" y1="22.5904" x2="2.00227" y2="0.684258" stroke="black" strokeWidth="2"/>
                        </svg>
                    </NavLink>
                </div>
            </div>
        )
    }
}

export default Vacancy;