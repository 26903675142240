import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import Main from './Main/Main';
import Vacancy from './Vacancy/Vacansy';
import Sale from './Sale/Sale';
import Stock from './Stock/Stock';
import Project from './Project/Project';

import localeUa from "./locale/ua.json";
import localeRu from "./locale/ru.json";

import './App.css';

class App extends Component {

    state = {
        locale: localeUa,
    }

    changeLang = (lang) => {
        this.setState({
            locale: lang
        })
    }

    render() {
        return (
            <div className="App">
                <Switch>
                    <Route exact path="/" render={props => <Main locale={this.state.locale}
                                                                 changeLangRu={() => this.changeLang(localeRu)}
                                                                 changeLangUa={() => this.changeLang(localeUa)}/>}/>
                    <Route exact path="/vacancy" render={props => <Vacancy locale={this.state.locale}/>}/>
                    <Route exact path="/sale" render={props => <Sale locale={this.state.locale}/>}/>
                    <Route exact path="/stock" render={props => <Stock locale={this.state.locale}/>}/>
                    <Route exact path="/project" render={props => <Project locale={this.state.locale}/>}/>
                </Switch>
            </div>
        );
    }
}

export default App;

