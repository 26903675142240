import React from 'react';
import {NavLink} from 'react-router-dom';

import project1 from "../img/project1.png";

const Project = ({locale}) => {
    return (
        <div className="modalProjects-wrapper">
            <div className="modalProjects">
                <div className="modalProjects-relative">
                    <div className="modalProjects-box">
                        <h2 className="modalVacancy-title">{locale.modalProjectsTitle}</h2>
                        {/*<h2 className="modalVacancy-title">Реализованные проекты</h2>*/}
                        <ul className="modalProjects-list">
                            <li className="modalProjects-listItem">
                                {/*<div className="projectImg1"></div>*/}
                                <img className="projectImg1" src={project1} alt=""/>
                                <ul className="modalProjects-listItem_list">
                                    <li className="modalProjects-listItem_item"><span
                                        className="modalProjects-listItem_itemLeftPart">{locale.modalProjectsListItem1[0]}</span>
                                        <span>{locale.modalProjectsListItem1[1]}</span></li>
                                    <li className="modalProjects-listItem_item"><span
                                        className="modalProjects-listItem_itemLeftPart">{locale.modalProjectsListItem2[0]}</span>
                                        <span>{locale.modalProjectsListItem2[1]}</span></li>
                                    <li className="modalProjects-listItem_item"><span
                                        className="modalProjects-listItem_itemLeftPart">{locale.modalProjectsListItem3[0]}</span>
                                        <span>{locale.modalProjectsListItem3[1]}</span></li>
                                    <li className="modalProjects-listItem_item"><span
                                        className="modalProjects-listItem_itemLeftPart">{locale.modalProjectsListItem4[0]}</span>
                                        <span>{locale.modalProjectsListItem4[1]}</span></li>
                                    <li className="modalProjects-listItem_item"><span
                                        className="modalProjects-listItem_itemLeftPart">{locale.modalProjectsListItem5[0]}</span>
                                        <span>{locale.modalProjectsListItem5[1]}</span></li>
                                    <li className="modalProjects-listItem_item"><span
                                        className="modalProjects-listItem_itemLeftPart">{locale.modalProjectsListItem6[0]}</span>
                                        <ul>
                                            <li className="modalProjects-listItem_item"><span
                                                className="modalProjects-listItem_itemLeftPart">{locale.modalProjectsListItem6[1][0]}</span>
                                                <span>{locale.modalProjectsListItem6[1][1]}</span></li>
                                            <li className="modalProjects-listItem_item"><span
                                                className="modalProjects-listItem_itemLeftPart">{locale.modalProjectsListItem6[2][0]}</span>
                                                <span>{locale.modalProjectsListItem6[2][1]}</span></li>
                                            <li className="modalProjects-listItem_item"><span
                                                className="modalProjects-listItem_itemLeftPart">{locale.modalProjectsListItem6[3][0]}</span>
                                                <span>{locale.modalProjectsListItem6[3][1]}</span></li>
                                        </ul>
                                    </li>
                                    <li className="modalProjects-listItem_item"><span
                                        className="modalProjects-listItem_itemLeftPart">{locale.modalProjectsListItem7[0]}</span>
                                        <span>{locale.modalProjectsListItem7[1]}</span></li>
                                    <li className="modalProjects-listItem_item"><span
                                        className="modalProjects-listItem_itemLeftPart">{locale.modalProjectsListItem8[0]}</span>
                                        <span>{locale.modalProjectsListItem8[1]}</span></li>
                                    <li className="modalProjects-listItem_item"><span
                                        className="modalProjects-listItem_itemLeftPart">{locale.modalProjectsListItem9[0]}</span>
                                        <span>{locale.modalProjectsListItem9[1]}</span></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <NavLink to="/">
                    <svg className="modalProjects-closeBtn" width="24" height="24" viewBox="0 0 24 24"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <line x1="1.31573" y1="22.2708" x2="23.2219" y2="1.71541" stroke="black"
                              strokeWidth="2"/>
                        <line x1="22.5576" y1="22.5904" x2="2.00227" y2="0.684258" stroke="black"
                              strokeWidth="2"/>
                    </svg>
                    </NavLink>
                </div>
            </div>
            {/*</div>*/}
        </div>
    )
}

export default Project;